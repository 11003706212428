import { render, staticRenderFns } from "./PhotoboothController.vue?vue&type=template&id=24048af5&scoped=true&"
import script from "./PhotoboothController.vue?vue&type=script&lang=js&"
export * from "./PhotoboothController.vue?vue&type=script&lang=js&"
import style0 from "./PhotoboothController.vue?vue&type=style&index=0&id=24048af5&prod&scoped=true&lang=scss&"
import style1 from "./PhotoboothController.vue?vue&type=style&index=1&id=24048af5&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24048af5",
  null
  
)

export default component.exports