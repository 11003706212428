var shuffleSeed = require("shuffle-seed");

function map(value, in_min, in_max, out_min, out_max, clamp = true) {
  let val =
    ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  if (clamp) {
    return this.clamp(val, out_min, out_max);
  }
  return val;
}



function clamp(input, min, max) {
  return input < min ? min : input > max ? max : input;
}

const removeElement = (array, itemId) => {
  const newArr = array.filter((el) => el.dbid !== itemId)
  return newArr

};

const shuffleArray = (array) => {
  return shuffleSeed.shuffle(array, Date.now());
};

const roundTo = (num, decimals = 2) => {
  return parseFloat(num.toFixed(decimals))
}

function lerp(start, end, t) {
  return start * (1 - t) + end * t;
}

function pickRandom(arr, amount = 1) {
  let start = 0;
  let end = arr.length -1;
  let options = Array.from({ length: end - start + 1 }, (_, index) => start + index);
  options = shuffleArray(options);
  let result = [];
  for(let i=0; i< Math.min(amount, arr.length); i++){
    result.push(arr[options.pop()]);
  }
  return result;
}


const TIME_DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
]

const formatTimeAgo = (date,lang) => {
  let duration = (date - new Date()) / 1000

  for (let i = 0; i < TIME_DIVISIONS.length; i++) {
    const division = TIME_DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return  new Intl.RelativeTimeFormat(lang, {
        numeric: "auto",
      }).format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
}

export { map, clamp, removeElement, shuffleArray, roundTo, formatTimeAgo, lerp, pickRandom};