<template>
  <div id="photoboothController" ref="photobooth" class="section" :class="controllerBackground">
    <template v-if="serverState < 3">
      <!-- GAME STARTED -->
      <template v-if="gameSubState == SUBSTATES.tutorial">
        <div id="tutorial" class="p-5">
          <div class="descripcion">
            <p class="mb-5 has-text-weight-bold">{{ $t('exp_phototbooth.tutorialTitle') }}</p>
            <img src="/assets/photobooth/tutorial.png" class="mb-5" width="103" height="181">
            <ol>
              <li>{{ $t('exp_phototbooth.tutorialLine1') }}</li>
              <li>{{ $t('exp_phototbooth.tutorialLine2') }}</li>
              <li>{{ $t('exp_phototbooth.tutorialLine3') }}</li>
            </ol>
          </div>

          <b-button @click="gameSubState = SUBSTATES.creating" expanded type="is-primary">{{ $t('exp_phototbooth.btnStart') }}</b-button>
        </div>
      </template>

      <template v-else>

        <div id="creatingAndCapturing" v-if="gameSubState >= SUBSTATES.creating && gameSubState <= SUBSTATES.convertingUploading">

          <!-- Sticker zone -->
          <div v-show="options.stickers.enabled && stickerList.length > 0" class="stickerDynamic p-5">
            <div class="dragContainer" :style="{ height: dragContainerHeight + 'px' }" ref="dragContainer">
              
              <p v-if="stickerList.filter(x => x.enabled).length === 0" class="p-5 has-text-grey">{{ $t('exp_phototbooth.stickersPlaceholder') }}</p>

              <template v-if="dragContainerHeight > 0">
                <template v-for="sticker in stickerList.filter(x => x.enabled)">
                  
                  <vue-draggable-resizable
                    :w="75"
                    :h="75"
                    :x="sticker.x * dragContainerWidth"
                    :y="sticker.y * dragContainerHeight"
                    :parent="true"
                    :key="sticker.id"
                    :resizable="false"
                    @dragging="sticker.onDrag">

                    <!-- Icono o el video -->
                    <template v-if="sticker.icon">
                      <img :src="sticker.icon.sizes.thumbnail.url" />
                    </template>
                    <template v-else-if="sticker.image">
                      <img :src="sticker.image.sizes.thumbnail.url" />
                    </template>
                    <template v-else>
                      <video :src="sticker.video.url" disableRemotePlayback muted autoplay loop></video>
                    </template>

                  </vue-draggable-resizable>
                </template>
              </template>
            </div>
            <div id="recordingState" :style="{ width: dragContainerWidth + 'px' }" :class="[gameSubStateName]">
              <div v-if="gameSubState == SUBSTATES.startingCapture">
                <d-icon icon="FaHourglass" size="is-small" />
                <span>{{ $t('exp_phototbooth.stateBeReady') }}</span>
              </div>
              <div v-if="gameSubState == SUBSTATES.capturing">
                <d-icon icon="FaVideo" size="is-small" />
                <span>{{ $t('exp_phototbooth.stateCapturing') }}</span>
              </div>
              <div v-if="gameSubState == SUBSTATES.convertingUploading">
                <d-icon icon="FaHourglass" size="is-small" />
                <span>{{ $t('exp_phototbooth.stateProcessing') }}</span>
              </div>
            </div>

          <div id="stickersContainer" v-if="options.stickers.enabled">
              <div v-for="sticker in stickerList" :key="sticker.id" class="sticker" @click="ToggleSticker(sticker)"
                :class="{ enabled: sticker.enabled }">
                <template v-if="sticker.icon">
                  <img :src="sticker.icon.sizes.thumbnail.url" />
                </template>
                <template v-else-if="sticker.image">
                  <img :src="sticker.image.sizes.thumbnail.url" />
                </template>
                <template v-else>
                  <video :src="sticker.video.url" disableRemotePlayback muted></video>
                </template>
              </div>
            </div>
          </div>

          <template v-if="gameSubState >= SUBSTATES.creating && gameSubState <= SUBSTATES.captureFinished">
            <b-button expanded v-if="options.captureMode == 'photo'" type="is-primary" @click="TakePhoto()"
              :disabled="gameSubState != SUBSTATES.creating">{{ $t('exp_phototbooth.btnTakePicture') }}</b-button>

              <div v-if="options.captureMode == 'video'" class="has-background-white-ter">

              <b-button type="is-primary" @click="TakeVideo()"
              :disabled="gameSubState != SUBSTATES.creating">
              <d-icon icon="FaVideo" size="is-medium" />
              </b-button>

              <div class="is-size-7">{{ $t('exp_phototbooth.ctaStartToRecord') }}</div>
            </div>
            
          </template>
        </div>

        <template v-if="gameSubState == SUBSTATES.captureFinished">

          <!-- Captured Image -->
          <div id="capturedImage" v-if="showCapturedImages">
            <div id="imagesContainer">
              <div v-for="image in capturedImages" :key="image.id">
                <img :src="image.mobile" />
              </div>
            </div>
            <div v-if="isSessionLeader">
              <b-button type="is-danger" @click="ReshootPhotos()">{{ $t('exp_phototbooth.btnReshoot') }}T</b-button>
              <b-button type="is-success" @click="SavePhotos()">{{ $t('exp_phototbooth.btnSave') }}</b-button>
            </div>
          </div>

          <!-- Captured Video -->
          <div id="capturedVideo" v-if="gameSubState == SUBSTATES.captureFinished" class="p-5">

            <div class="main-container">
              <div class="has-text-centered">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M54 6H6V42H54V6ZM0 0V60H60V0H0Z" fill="#0A0A0A"/>
                  <path d="M37.6418 23.8404L26.3272 17.0124C25.4079 16.4579 24 16.996 24 18.3675V32.0202C24 33.2506 25.3083 33.9922 26.3272 33.3753L37.6418 26.5506C38.6511 25.9436 38.6543 24.4474 37.6418 23.8404Z" fill="#0A0A0A"/>
                </svg>
                <h2 class="title has-text-black-bis mb-2">{{ $t('exp_phototbooth.videoReadyTitle') }}</h2>
                <div class="is-size-4">{{ $t('exp_phototbooth.videoReadyDescription') }}</div>
              </div>
            </div>
        
            <div v-if="isSessionLeader" class="bottom-container">
              <b-button type="is-primary" expanded outlined @click="ReshootVideo()">{{ $t('exp_phototbooth.btnRedo') }}</b-button>
              <b-button type="is-primary" expanded @click="SaveVideos()">{{ $t('exp_phototbooth.btnContinue') }}</b-button>
            </div>
            <b-progress class="mt-2" type="is-primary" size="is-small" :value="sharingTimePercentLeft"></b-progress>

          </div>
        </template>
      </template>
    </template>
    <template v-if="serverState == 3">
      <div class="time-out has-text-black-bis has-text-centered">
        <div>
          <div>
            <d-icon icon="FaHourglassEnd" size="is-large"/>
            <p class="is-size-2 has-text-weight-bold">{{ $t('exp_phototbooth.timeOut') }}</p>
            <p class="is-size-5">{{ $t('exp_phototbooth.thanksForParticipating') }}</p>
          </div>
        </div>
        <b-button type="is-primary" @click="$parent.GoToMenu()" expanded>{{ $t('exp_phototbooth.backToMenu') }}</b-button>
      </div>
     
    </template>
    <canvas id="confetti-canvas"></canvas>
  </div>

</template>
  
<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import ConfettiGenerator from "confetti-js"
const utils = require("@/components/utils.js");

export default {
  components: {
    VueDraggableResizable
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      SUBSTATES: Object.freeze({
        tutorial: 0,
        creating: 1,
        startingCapture: 2,
        capturing: 3,
        convertingUploading: 4,
        captureFinished: 5
      }),
      gameSubState: 0,

      activeSlide: 0,
      sliderId: 0,
      sharingTimePercentLeft: 100,
      sharingTimeStarted: null,
      sharingTimeInterval: null,
      
      fetchingVideoShare: false,

      showTutorial: true,
      stickerList: [],
      screenTakingPicture: false,
      showCapturedImages: false,
      capturedImages: [],
      capturedVideo: null,
      showCapturedVideo: false,
      showCapturedVideoTimer: null,

      isSessionLeader: false,
      dragContainerHeight: 0,
      dragContainerWidth: 0,
    };
  },
  computed: {
    gameSubStateName() {
      return Object.keys(this.SUBSTATES)[this.gameSubState]
    },
    serverState() {
      return this.$parent.serverState
    },
    // GetImageUrlFromIndex(index) {
    //   console.log(index, this.capturedImages)
    //   return this.capturedImages[index]?.mobile;
    // },
    moreOnePlayer() {
      return this.$parent.room.experience.component[0].queue.maxPlayers > 1
    },
    controllerBackground() {
      return (this.gameSubState == this.SUBSTATES.captureFinished || this.serverState == 3)  && 'has-background-primary-light'
    }
  },
  methods: {
    SetSharingTimePercent(){
      this.sharingTimePercentLeft = 100 - ((Date.now() - this.sharingTimeStarted) / 1000 /( (this.options.clipDuration * 2) - 1) * 100)
      // console.log("SetSharingTimePercent", this.sharingTimePercentLeft)
      if(this.sharingTimePercentLeft <= 0){
        clearInterval(this.sharingTimeInterval)
      }
    },
    isIOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    ToggleSticker(sticker) {
      // console.log("ToggleSticker", sticker)
      sticker.enabled = !sticker.enabled

      // if (sticker.enabled) {
      //   sticker.x = Math.random() * (1 - 75 / this.dragContainerWidth)
      //   sticker.y = Math.random() * (1 - 75 / this.dragContainerHeight)
      // }
      
      this.$forceUpdate();
      // console.log(sticker)
      // Emit to server
      // const pos = this.StickerPositionRemap(sticker.x,sticker.y)
      this.$socket.client.emit('gameMessage', {
        type: 'StickerToggled',
        id: sticker.id,
        state: sticker.enabled,
        x: sticker.x,
        y: sticker.y
      });
    },
    onStickerDrag(sticker, x, y) {
      x /= this.dragContainerWidth - 75;
      y /= this.dragContainerHeight - 75;
      sticker.x = x;
      sticker.y = y;
      this.$forceUpdate();
      // emit to server
      // const pos = this.StickerPositionRemap(sticker.x,sticker.y)
      // console.log("*", pos.x, pos.y)
      this.$socket.client.emit('gameMessage', {
        type: 'StickerMoved',
        id: sticker.id,
        x: sticker.x,
        y: sticker.y
      });
    },
    StickerPositionRemap(x,y){
      // Ingresa un x,y entre 0 y 1 que representa el cuadrado donde se ubican los stickers
      // Tenemos que mapearlo adentro de un trapecio definido por:
      // options.stickerLimitY es el alto maximo
      // options.stickerTopXScale es el ancho maximo arriba de todo
      // La base del trapecio es siempre 1
      let _y = utils.map(y, 0, 1, this.options.stickers.stickerLimitY, 1)
      // let _xmultiplier = utils.map(_y, 0, this.options.stickers.stickerLimitY, 1, this.options.stickers.stickerTopXScale)
      let topSidesOffset = (1 - this.options.stickers.stickerTopXScale) / 2
      let xTopTarget = utils.map(x, 0, 1, topSidesOffset, Math.abs(topSidesOffset) + this.options.stickers.stickerTopXScale)

      let bottomSidesOffset = (1 - this.options.stickers.stickerBottomXScale) / 2
      let xBottomTarget = utils.map(x, 0, 1, bottomSidesOffset, Math.abs(bottomSidesOffset) + this.options.stickers.stickerBottomXScale)
      let _x = utils.lerp(xTopTarget, xBottomTarget, y) // hago un lerp entre el x real y el calculado, segun la posicion en y
      // console.log("orig", x, y, "remap", _x, _y)
      return {x: _x, y: _y}
    },
    SavePhotos() {
      this.$socket.client.emit('gameMessage', {
        type: 'CaptureFinish',
      });
    },
    ReshootPhotos() {
      // Eliminar las fotos tomadas por ID con axios
      this.$socket.client.emit('gameMessage', {
        type: 'ReshootPhoto',
        ids: this.capturedImages.map(image => image.id)
      });
      this.screenTakingPicture = false;
      this.showCapturedImages = false;
      this.capturedImages = [];
    },
    TakePhoto() {
      this.isSessionLeader = true;
      this.showCapturedImages = false;
      this.capturedImages = [];
      this.$socket.client.emit('gameMessage', {
        type: 'TakePhoto',
        userid: this.$store.state.user.profile.id
      });
    },
    TakeVideo() {
      console.log("** TakeVideo")
      this.isSessionLeader = true;
      this.showCapturedVideo = false;
      this.capturedVideo = null;
      this.$socket.client.emit('gameMessage', {
        type: 'TakeVideo',
        userid: this.$store.state.user.profile.id
      });
      // Analytics
      this.$TrackUserEvent("Shoot Video", {
        experienceId: this.$store.state.user.experienceid,
        experienceName: this.$store.state.user.experience,
        space: this.$store.state.user.space,
      })
      console.log("Analytics Shoot Video")
    },
    ReshootVideo() {
      this.$socket.client.emit('gameMessage', {
        type: 'ReshootVideo'
      });
      this.gameSubState = this.SUBSTATES.creating
      // Analytics
      this.$TrackUserEvent("Reshoot Video", {
        experienceId: this.$store.state.user.experienceid,
        experienceName: this.$store.state.user.experience,
        space: this.$store.state.user.space,
      })
      console.log("Analytics ReShoot Video")
    },
    SaveVideos() {
      this.$socket.client.emit('gameMessage', {
        type: 'SaveVideo'
      });
      // Analytics
      this.$TrackUserEvent("Save Video", {
        experienceId: this.$store.state.user.experienceid,
        experienceName: this.$store.state.user.experience,
        space: this.$store.state.user.space,
      })
      console.log("Analytics Save Video")
      this.$parent.GoToMenu()
    },
    async ShareVideo() {
      try {
        this.fetchingVideoShare = true;
        const response = await fetch(this.capturedVideo.url);
        if (!response.ok) {
          this.fetchingVideoShare = false;
          throw new Error('Network response was not ok');
        }

        const videoBlob = await response.blob();
        console.log('Blob type:', videoBlob.type); // Log the blob type

        const videoFile = new File([videoBlob], 'TOM-Experiencia-Photobooth.mp4', { type: 'video/mp4' });
        console.log('File:', videoFile); // Log the file

        let shareData = {
          files: [videoFile],
        };
        
        if (navigator.share && typeof navigator.share === 'function') {
          if (navigator.canShare && navigator.canShare(shareData)) {
            this.fetchingVideoShare = false;
            await navigator.share(shareData);
          } else {
            // File sharing not supported
            console.error('File sharing is not supported in this browser.');
            this.fetchingVideoShare = false;
          }
        } else {
          // Fallback for browsers that do not support Web Share API
          // Convert blob to data URL and provide a link or use other methods for sharing
          const reader = new FileReader();
          reader.onload = function(event) {
            const dataUrl = event.target.result;
            // Use the data URL for sharing or provide a download link
            console.log('Data URL:', dataUrl);
            // Handle sharing or provide fallback for sharing on non-supported browsers
          };
          reader.readAsDataURL(videoBlob);
          this.fetchingVideoShare = false;
        }
      } catch (error) {
        // Handle errors here
        console.error('Error fetching and sharing video:', error);
        this.fetchingVideoShare = false;
      }
    },
    ShowConfetti() {

       var confetti = new ConfettiGenerator({
        "target": "confetti-canvas",
        "max": "80",
        "size": "1",
        "animate": true,
        "props": ["triangle"],
        "colors": [[212, 175, 55]],
        "clock": "50",
        "rotate": true,
        "height": this.$refs.photobooth.clientHeight,
        "start_from_edge": true,
        "respawn": false
      })

      confetti.render()
    },
  },
  watch: {
    gameSubState(state) {

      if (state == this.SUBSTATES.captureFinished) return this.ShowConfetti()

      if (state == this.SUBSTATES.creating) {
        // Se escondio el tutorial
        // Calculo el alto del drag container
        // Drag container height
        this.$nextTick(() => {
          this.dragContainerWidth = this.$refs.dragContainer.offsetWidth
          this.dragContainerHeight = this.$refs.dragContainer.offsetWidth / 4 * 3
        })
        return
      }
    }
  },
  mounted() {
  },
  beforeUnmount() {
  },
  sockets: {
    stickersPosition(data){
      console.log("stickersPosition", data)
      this.stickerList = []
      if (this.options.stickers.enabled && this.options.stickers.list.length > 0) {
        this.options.stickers.list.forEach((item) => {
          const receivedStickerData = data.stickers.find(x => x.id == item.id)
          item.x = receivedStickerData.x * 0.8;
          item.y = receivedStickerData.y * 0.8;
          item.enabled = receivedStickerData.enabled;
          item.onDrag = (x, y) => { this.onStickerDrag(item, x, y) }
          this.stickerList.push(item);
        })
      }

    },
    convertingUploading(){
      this.gameSubState = this.SUBSTATES.convertingUploading
    },
    countdownStarted() {
      this.screenTakingPicture = true;
      this.showCapturedImages = false;
      this.gameSubState = this.SUBSTATES.startingCapture;
    },
    photoTaken() {
      this.screenTakingPicture = true;
      this.showCapturedImages = false;
    },

    capturedImages(data) {
      console.log("photosTaken", data);
      this.capturedImages = data.capturedImages
      this.showCapturedImages = true;
      this.gameSubState = this.SUBSTATES.captureFinished;
    },
    capturedVideo(data) {
      console.log("capturedVideo", data);
      this.capturedVideo = data.video
      this.showCapturedVideo = true;
      this.$nextTick(() => {
        console.log("Start loading", document.getElementById('capturedVideo'))
      });
      // this.$refs
      this.gameSubState = this.SUBSTATES.captureFinished;
      this.sharingTimePercentLeft = 100;
      this.sharingTimeStarted = Date.now();
      this.sharingTimeInterval = setInterval(this.SetSharingTimePercent, 50)
      // localStorage.setItem("openPhotoboothVideo", "1");
    },
    userDecideRedo(){
      this.gameSubState = this.SUBSTATES.captureFinished;
      this.sharingTimePercentLeft = 100;
      this.sharingTimeStarted = Date.now();
      this.sharingTimeInterval = setInterval(this.SetSharingTimePercent, 50)
      // localStorage.setItem("openPhotoboothVideo", "1");
    },
    captureStarted() {
      this.gameSubState = this.SUBSTATES.capturing;
    }
  }
};
</script>
  
<style scoped lang="scss">

@import '@/styles/variables.scss';

#photoboothController {
  padding: 0 !important;
  position: relative;
}

#confetti-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

#tutorial {
  display: flex;
  flex-direction: column;
  height: 100%;

  .descripcion {
    flex: 1;

    img {
      display: block;
      margin-inline: auto;
    }

    ol {
      margin-left: 1rem !important;

      li {
        margin-bottom: 0.5rem !important
      }
    }
  }
}

#creatingAndCapturing {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  .has-background-white-ter {
    padding: .5em;
    text-align: center;

    $size: 65px;
    button {
      width: $size;
      height: $size;
      border: solid 7px white;
      margin-top: 20px - $size;
      border-radius: 50%;
      box-shadow: 0 4px 7px rgba(black,.08);
    }
  }
}

.dragContainer {
  border-radius: 4px;
  width: 100%;
  background: #F5F5F5;
  margin-bottom: 10px;
  padding-bottom: 75%;

  p {
    position: absolute;
  }
  
  .draggable {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    border-radius: 3px;
    overflow: hidden;
  }

}

#recordingState {
  opacity: 0;
  position: absolute;
  height: 30px;
  background-color: $primary;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  margin-top: -40px;
  pointer-events: none;
  transition: opacity .3s ease-in-out;

  div {
    display: flex;
    align-items: center;
    gap: .25em;
  }

  &.startingCapture {
    opacity: 1;
    background-color: #EFAC2B;
  }

  &.capturing {
    opacity: 1;
  }
  &.convertingUploading{
    opacity: 1;
    background-color: green;
  }
}

.stickerDynamic {
  flex: 1;
}

#stickersContainer {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 10px;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #e7e7e7;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary;
  }

  .sticker {
    flex-shrink: 0;
    padding: 1px;
    display: flex;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    background: #FAFAFA;
    border: 2px solid #F5F5F5;

    &>* {
      width: 60px;
      height: 60px;
      opacity: .5;
      object-fit: contain;
    }

    &.enabled {
      border: 2px solid $primary;

      &>* {
        opacity: 1;
      }
    }
  }
}

.draggable {
  position: absolute;
}

#videoRecordingIndicator {
  display: flex;
}


#capturedVideo {

  height: 100%;
  display: flex;
  flex-direction: column;

  .main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > div {
      position: relative;

      svg {
        position: absolute;
        transform: translate(-50%, -6rem);
      }
    }
  } 

  .bottom-container {
    display: flex;
    gap: .5em;
  }
}

.time-out {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > div {
      position: relative;
    }
  }

  .icon-hourglass-end {
    position: absolute;
    left: 50%;
    top: -4.5em;
    transform: translateX(-50%);
  }
}

</style>

<style>
.progress {
  border-radius: 4px;
}
</style>
